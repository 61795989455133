// WITH SOURCE
type Config = {
  queryStr?: string;
  numResults?: number;
  metadataFilter?: string;
  language?: string;
  conversationId?: string;
};

// WITH CORPUS
// type Config = {
//   queryStr?: string;
//   numResults?: number;
//   metadataFilter?: string;
//   corpusName?: string;
//   language?: string;
//   conversationId?: string;
// };

// WITH SOURCE
export const sensResumeRequest = async ({
  queryStr,
  numResults,
  metadataFilter,
  language,
  conversationId
}: Config) => {

  return fetch(`/resume/`, {
    method: "POST",
    body: JSON.stringify({
      query: queryStr,
      numResults: numResults,
      metadataFilter: metadataFilter,
      responseLang: language,
      conversationId: conversationId
    }),
    headers: {
    "Content-type": "application/json; charset=UTF-8"
    }
  })
  .then(result => {
    return result.json()
  });

};


// WITH CORPUS
// export const sensResumeRequest = async ({
//   queryStr,
//   numResults,
//   metadataFilter,
//   corpusName,
//   language,
//   conversationId
// }: Config) => {

//   return fetch(`/resume/`, {
//     method: "POST",
//     body: JSON.stringify({
//       query: queryStr,
//       numResults: numResults,
//       metadataFilter: metadataFilter,
//       corpusName: corpusName,
//       responseLang: language,
//       conversationId: conversationId
//     }),
//     headers: {
//     "Content-type": "application/json; charset=UTF-8"
//     }
//   })
//   .then(result => {
//     return result.json()
//   });
// };