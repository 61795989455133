// OK

import {
  createContext,
  useEffect,
  useContext,
  useState,
  ReactNode,
} from "react";

import {
  SummaryLanguage,
  SUMMARY_LANGUAGES,
  Config,
  ConfigProp,
  ConfigContextType, 
  ExampleQuestions, 
  SearchLinks, 
  SummaryDefaultLanguage,
  Filters,
  AuthConfig,
  InterfaceType
} from "@customTypes/search";
import { Socket } from "socket.io-client";

const requiredConfigVars = ["questions", "searchTitlePre", "searchLinks"];

// On créé un context vide, qui sera transmis à travers les composants
const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

type Props = {
  socket: Socket,
  children: ReactNode;
};

const prefixConfig = (
  config: Record<string, string | undefined>,
  newPrefix: string = "",
  existingPrefix: string = ""
) => {
  const prefixedConfig = Object.keys(config).reduce((accum, key) => {
    if (key.startsWith(existingPrefix)) {
      const unprefixedKey = newPrefix + key.slice(existingPrefix.length, existingPrefix.length + 1).toUpperCase() + key.slice(existingPrefix.length + 1);
      accum[unprefixedKey] = config[key];
    } else {
      const unprefixedKey = `${newPrefix}${key.slice(0, 1).toUpperCase() + key.slice(1)}`;
      accum[unprefixedKey] = config[key];
    }
    return accum;
  }, {} as Record<string, string | undefined>);
  return prefixedConfig;
};

const validateLanguage = (lang: string, defaultLanguage: SummaryLanguage): SummaryLanguage => {
  if ((SUMMARY_LANGUAGES as readonly string[]).includes(lang)) {
    return lang as SummaryLanguage;
  }
  return defaultLanguage;
};

export const ConfigContextProvider = ({ socket, children }: Props) => {

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [missingConfigProps, setMissingConfigProps] = useState<string[]>([]);
  const [filters, setFilters] = useState<Filters>({
    isEnabled: false,
    labelsWitValues: [],
    valueToLabelMap: {}
  });

  // WITH CORPUS 
  // const [authConfig, setAuthConfig] = useState<AuthConfig>({
  //   multipleCorpus: false,
  //   labelsWitValues : [],
  //   valueToLabelMap : {}
  // })
  
  const [exampleQuestions, setExampleQuestions] = useState<ExampleQuestions>(
    []
  );
  const [searchLinks, setSearchLinks] = useState<SearchLinks>(
    []
  );
  const [startContentTag, setStartContentTag] = useState<string>("");
  const [endContentTag, setEndContentTag] = useState<string>("");
  const [startSnippetTag, setStartSnippetTag] = useState<string>("");
  const [endSnippetTag, setEndSnippetTag] = useState<string>("");
  const [lineSeparatorTag, setLineSeparatorTag] = useState<string>("");
  const [columnSeparatorTag, setColumnSeparatorTag] = useState<string>("");
  const [newLineTabSeparatorTag, setNewLineTabSeparatorTag] = useState<string>("");  
  const [newLineSeparatorTag, setNewLineSeparatorTag] = useState<string>("");  
  const [startRefTag, setStartRefTag] = useState<string>(""); 
  const [endRefTag, setEndRefTag] = useState<string>("");  
  const [startButtonTag, setStartButtonTag] = useState<string>(""); 
  const [endButtonTag, setEndButtonTag] = useState<string>("");  
  const [startFileTag, setStartFileTag] = useState<string>(""); 
  const [endFileTag, setEndFileTag] = useState<string>("");  

  const [searchTitlePre, setSearchTitlePre] = useState<string>("");
  const [interfaceType, setInterfaceType] = useState<InterfaceType>("searchEngine");

  const [summary, setSummary] = useState<SummaryDefaultLanguage>({
    defaultLanguage: process.env.REACT_APP_default_language ? process.env.REACT_APP_default_language : "fra"
  });

  useEffect(() => {
    const loadConfig = async () => {
      let config: Config;
      config = prefixConfig(process.env,"config", "REACT_APP_");
      
      const {
        configQuestions,
        configSearchTitlePre,
        configSearchLinks,
        configStartContentTag,
        configEndContentTag,
        configStartSnippetTag,
        configEndSnippetTag,
        configLineSeparatorTag,
        configColumnSeparatorTag,
        configNewLineSeparatorTag,
        configNewLineTabSeparatorTag,
        configStartRefTag,
        configEndRefTag,
        configStartButtonTag,
        configEndButtonTag,
        configStartFileTag,
        configEndFileTag,
        configDefaultLanguage,
        configLabelsWitValues,
        configMultipleCorpus,
        configInterfaceType
      } = config;

      if (configQuestions) {
        setExampleQuestions(JSON.parse(configQuestions));
      }
      if(configSearchLinks){
        setSearchLinks(JSON.parse(configSearchLinks))
      }
      if(configSearchTitlePre){
        setSearchTitlePre(configSearchTitlePre)
      }
      if(configStartContentTag){
        setStartContentTag(configStartContentTag)
      }
      if(configEndContentTag){
        setEndContentTag(configEndContentTag)
      }
      if(configStartSnippetTag){
        setStartSnippetTag(configStartSnippetTag)
      }
      if(configEndSnippetTag){
        setEndSnippetTag(configEndSnippetTag)
      }
      if(configLineSeparatorTag){
        setLineSeparatorTag(configLineSeparatorTag)
      }
      if(configColumnSeparatorTag){
        setColumnSeparatorTag(configColumnSeparatorTag)
      }
      if(configNewLineTabSeparatorTag){
        setNewLineTabSeparatorTag(configNewLineTabSeparatorTag)
      }
      if(configNewLineSeparatorTag){
        setNewLineSeparatorTag(configNewLineSeparatorTag)
      }
      if(configStartRefTag){
        setStartRefTag(configStartRefTag)
      }
      if(configEndRefTag){
        setEndRefTag(configEndRefTag)
      }
      if(configStartButtonTag){
        setStartButtonTag(configStartButtonTag)
      }
      if(configEndButtonTag){
        setEndButtonTag(configEndButtonTag)
      }
      if(configStartFileTag){
        setStartFileTag(configStartFileTag)
      }
      if(configEndFileTag){
        setEndFileTag(configEndFileTag)
      }
      if(configInterfaceType){
        setInterfaceType(configInterfaceType)
      }
      
      setIsConfigLoaded(true);
  

      const missingConfigProps = requiredConfigVars.reduce(
        (accum, configVarName) => {
          if (config[`config${configVarName.slice(0, 1).toUpperCase() + configVarName.slice(1)}` as ConfigProp] === undefined)
            accum.push(configVarName);
          return accum;
        },
        [] as string[]
      );
      setMissingConfigProps(missingConfigProps);


      setSummary({
        defaultLanguage: validateLanguage(configDefaultLanguage as SummaryLanguage, "fra"),
      });

      const sources : Array<Record<string, string>> = JSON.parse(configLabelsWitValues ?? "")
      const sourceValueToLabelMap : Record<string, string> = {};
      for (let i = 0; i < sources.length; i++) {
        sourceValueToLabelMap[sources[i].value] = sources[i].label;
      }

      setFilters({
        isEnabled: JSON.parse(configMultipleCorpus ?? "false"),
        labelsWitValues: JSON.parse(configLabelsWitValues ?? ""),
        valueToLabelMap : sourceValueToLabelMap
      });


      // WITH CORPUS
      // setAuthConfig({
      //   multipleCorpus: JSON.parse(configMultipleCorpus ?? "false"),
      //   labelsWitValues: JSON.parse(configLabelsWitValues ?? ""),
      //   valueToLabelMap: sourceValueToLabelMap
      // })

    };
    loadConfig();
  }, []);

  // WITH SOURCE
  return (
    <ConfigContext.Provider
      value={{
        socket,
        interfaceType,
        isConfigLoaded,
        missingConfigProps,
        filters,
        summary,
        exampleQuestions,
        searchTitlePre,
        searchLinks,
        startContentTag,
        endContentTag,
        startSnippetTag,
        endSnippetTag,
        lineSeparatorTag,
        columnSeparatorTag,
        newLineTabSeparatorTag,
        newLineSeparatorTag,
        startRefTag,
        endRefTag,
        startButtonTag,
        endButtonTag,
        startFileTag,
        endFileTag
      }}
    >
      {children}
    </ConfigContext.Provider>
  );

  // WITH CORPUS
  // return (
  //   <ConfigContext.Provider
  //     value={{
  //       interfaceType,
  //       isConfigLoaded,
  //       missingConfigProps,
  //       filters,
  //       authConfig,
  //       summary,
  //       exampleQuestions,
  //       searchTitlePre,
  //       searchLinks,
  //       startContentTag,
  //       endContentTag,
  //       startSnippetTag,
  //       endSnippetTag,
  //       lineSeparatorTag,
  //       columnSeparatorTag,
  //       newLineTabSeparatorTag,
  //       newLineSeparatorTag,
  //       startRefTag,
  //       endRefTag,
  //       startButtonTag,
  //       endButtonTag,
  //       startFileTag,
  //       endFileTag
  //     }}
  //   >
  //     {children}
  //   </ConfigContext.Provider>
  // );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(
      "useConfigContext must be used within a ConfigContextProvider"
    );
  }
  return context;
};
