import { Box } from "@mui/material";
import { VuiSpacer, VuiText, VuiFlexItem, VuiFlexContainer } from "@adventera/vectara-ui";
import { SummaryType } from "@customTypes/search";
import TextWithContents from "@ui/components/summaryTextWithContents/SummaryTextWithContents";

type SummaryProps = {
  summary?: SummaryType;
  children?: React.ReactNode;
  selectedCitationPosition?: number;
  onClickCitation?: (position: number) => void;
};

const decorateSummary = (
  summary: SummaryType,
) => {

  return (
    <Box sx={{
      bgcolor: 'background.paper',
      boxShadow: 1,
      borderRadius: 2,
      p: 2,
      minWidth: 300,
    }}>
      <VuiFlexContainer>
        <VuiFlexContainer alignItems='center' justifyContent='center'>
          <VuiFlexItem>
            <img src="img/numy_icon.png" alt="Réponse" height="80" />
          </VuiFlexItem>
        </VuiFlexContainer>
        <VuiFlexItem>
          <p>
            {
              summary.texts &&
              summary.texts.map((text, index) => (
                  <TextWithContents
                    key={index}
                    allText={text}
                    allContent={summary.contents[index]}
                  />
              ))
            }
            {
              !summary.texts &&
              <p>Le résumé n'a pas pu être généré, veuillez réessayer</p>
            }
          </p>
        </VuiFlexItem>
      </VuiFlexContainer>
    </Box>
  )
};

export const Summary = ({ summary, children }: SummaryProps) => {
  let content;
  if (summary) {
    content = decorateSummary(summary);
  } else {
    content = children;
  }
  return (
    <>
      <VuiSpacer size="s" />
      <div className="vuiSummary">
        <VuiText size="m">{content}</VuiText>
      </div>
    </>
  );
};
