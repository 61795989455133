import { VuiSpacer } from "@adventera/vectara-ui";
import { SearchResult } from "../searchResult/SearchResult";
import { SearchResponseDoc, SearchResultListProps} from "@customTypes/search";

export const SearchResultList = ({results}: SearchResultListProps) => {
  var docs: SearchResponseDoc[] = [];
  for (var i = 0; i < results?.response.length!; i++) {
    var docIdx: number = Number(results?.response[i].documentIndex);
    docs[i] = results!.document[docIdx];
  }
  const content = (
    <>
      <VuiSpacer size="l" />
      {results?.response.map((_, i) => (
        <SearchResult doc={docs[i]!} res={results?.response[i]!} position={i} key={i}/>
      ))}
    </>
  );

  return (
    <>
      {content}
    </>
  );
};
