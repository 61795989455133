import React from 'react';
import { VuiText } from "@ui/components";
import TextWithContents from '../textWithContents/TextWithContents';
import { AllTextsWithContentsProps } from '@customTypes/search';

import {
  VuiFlexContainer,
  VuiFlexItem,
} from "@adventera/vectara-ui";

const AllTextsWithContents = ({ paragraphes, columnNames, textSize }: AllTextsWithContentsProps) => {
  return (
    <VuiFlexContainer
      direction="row"
      alignItems="center"
      spacing="l"
    >
      <VuiFlexItem className='vuiImgContainer'>
        <VuiText size={textSize}>
          {
            paragraphes.map((textsAndContents, idx) => (
              <p key={idx} className='text_result'>
                  {
                    textsAndContents.texts.map((text, index) => (
                      <TextWithContents
                        key={index}
                        allText={text}
                        allContent={textsAndContents.contents[index]}
                        inContent={textsAndContents.inContent}
                        headerColumns={columnNames}
                      />
                    ))
                  }
              </p>
              
            ))
          }
        </VuiText>
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};

export default AllTextsWithContents;