import { BiTimeFive, BiTrash } from "react-icons/bi";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiIcon,
  VuiText,
  VuiTextColor,
  VuiTitle,
  VuiMenu,
  VuiMenuItem
} from "@adventera/vectara-ui";
import "./historyDrawer.scss";
import { VuiButtonEmpty, VuiDrawer} from "@ui/components";
import { HistoryDrawerProps, humanizeLanguage } from "@customTypes/search";

import { useSearchContext } from "@services/context/SearchContext";
import { useConfigContext } from "@services/context/ConfigurationContext";


export const HistoryDrawer = ({ isOpen, onClose }: HistoryDrawerProps) => {
  const { onSearchFromHistory, history, clearHistory } = useSearchContext();
  // WITH SOURCE
  const {filters} = useConfigContext();
  // WITH CORPUS
  // const {authConfig} = useConfigContext();

  return (
    <VuiDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={
        <VuiFlexContainer
          justifyContent="spaceBetween"
          alignItems="center"
          spacing="xs"
          // HISTORY_HEADER
          className="historyTitle"
        >
          <VuiFlexItem>
            <VuiIcon size="s">
              <BiTimeFive />
            </VuiIcon>
          </VuiFlexItem>

          <VuiFlexItem>
            {/* HISTORY_HEADER */}
            <VuiTitle size="m" className="historyTitle">
              <h2>Historique</h2>
            </VuiTitle>
          </VuiFlexItem>
        </VuiFlexContainer>
      }
      footer={
        <VuiFlexContainer
          justifyContent="center"
          alignItems="center"
        >
          <VuiFlexItem>
              <VuiButtonEmpty
                className="vuiButtonEmpty--deleteHistory"
                color="normal"
                size="m"
                onClick={clearHistory}
                icon={
                  <VuiIcon size="m">
                    <BiTrash/>
                  </VuiIcon>
                }
              >
                <p>Supprimer l'historique</p>
              </VuiButtonEmpty>
          </VuiFlexItem>
        </VuiFlexContainer>
      }
    >
      {!history.length ? (
        <VuiText>
          <VuiTextColor color="subdued">
            <p>Aucun historique disponible.</p>
          </VuiTextColor>
        </VuiText>
      ) : (
        <VuiMenu>
          {/* WITH SOURCE */}
          {history.map(({ query, language, filterValue, date, hidden }) => {
            let content = <></>;

            if(!hidden){
              let subTitle = date;
              if (language) {
                subTitle += ` • ${humanizeLanguage(language)}`;
              }
              
              if(filters.isEnabled){
                subTitle +=  filterValue
                ?  ` • ${filters.valueToLabelMap[filterValue]}`
                : ` • Toutes les sources`
              }
              
              content = (
                <VuiMenuItem
                key={date}
                title={query}
                text={subTitle}
                onClick={() => {
                  onSearchFromHistory({value: query, language, filterValue});
                  onClose();
                }}
              />
              );
            };
            return (content);
          })}

          {/* WITH CORPUS */}
          {/* {history.map(({ query, language, filterValue, corpusNameValue, date, hidden }) => {
            let content = <></>;

            if(!hidden){
              let subTitle = date;
              if (language) {
                subTitle += ` • ${humanizeLanguage(language)}`;
              }
              
              if(authConfig.multipleCorpus){
                subTitle +=  corpusNameValue
                ?  ` • ${authConfig.valueToLabelMap[corpusNameValue]}`
                : ` • Toutes les sources`
              }
              
              content = (
                <VuiMenuItem
                key={date}
                title={query}
                text={subTitle}
                onClick={() => {
                  onSearchFromHistory({value: query, language, filterValue, corpusNameValue});
                  onClose();
                }}
              />
              );
            };
            return (content);
          })} */}
        </VuiMenu>
      )}
    </VuiDrawer>
  );
};
