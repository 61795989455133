import {PreTextPostString, ConfigContextType} from "@customTypes/search"


export const parseContext = (source: string, config : ConfigContextType, inContent: boolean = false, type: string = "pre"): PreTextPostString => {
  
  const START_SNIPPET_TAG = config.startSnippetTag;
  const END_SNIPPET_TAG = config.endSnippetTag;
  
  if (inContent) {
    return { pre: "", text: source, post: "" }
  }

  const [pre, textAndPost] =
    source.indexOf(START_SNIPPET_TAG) !== -1 ? source.split(START_SNIPPET_TAG) : ["", source];
  const [text, post] =
    textAndPost.indexOf(END_SNIPPET_TAG) !== -1
      ? textAndPost.split(END_SNIPPET_TAG)
      : [textAndPost, ""];

  if (source.indexOf(START_SNIPPET_TAG) !== -1) {
    return { pre, text, post };
  }
  if (text && pre === "" && post === "") {
    return type === "pre" ? { pre: text, text: "", post: "" } : { pre: "", text: "", post: text };
  }
  return { pre, text, post };
};