import { BrowserRouter as Router } from "react-router-dom";
import { SearchView } from "@views/search/SearchView";

import { useConfigContext, ConfigContextProvider } from "@services/context/ConfigurationContext";
import { SearchContextProvider } from "@services/context/SearchContext";
import "./App.scss";

import socketIO from 'socket.io-client';
const socket = socketIO(undefined);


const AppRoutes = () => {

  const { missingConfigProps } = useConfigContext();

  if (missingConfigProps.length) {
    return (
      <div>
        These environment variables are missing: {missingConfigProps.join(", ")}. They need to be defined for the app to
        function.
      </div>
    );
  }

  return (
    <Router>
      <SearchContextProvider>
          <SearchView />
      </SearchContextProvider>
    </Router>
  );
};

export const App = () => (
  <ConfigContextProvider socket={socket}>
      <AppRoutes />
  </ConfigContextProvider>
);

