import classNames from "classnames";
import { VuiLinkProps } from "@customTypes/search";

export const VuiLink = ({
  children,
  href,
  target,
  className,
  ...rest
}: VuiLinkProps) => {
  const props: {
    target?: string;
    rel?: string;
  } = { ...rest };

  if (target === "_blank") {
    props.target = target;
    props.rel = "noreferrer noopener";
  }

  return (
    <a className={classNames("vuiLink", className)} href={href} {...props}>
      {children}
    </a>
  );
};
