import { useState } from "react";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiHorizontalRule,
  VuiSpacer,
  VuiSpinner,
  VuiTitle,
} from "@adventera/vectara-ui";
import { SearchControls } from "./controls/SearchControls"; 
import SearchSummary from "./results/searchSummary/SearchSummary"
import { SearchResults } from "./results/searchResults/SearchResults";
import { ExampleQuestions } from "./landingPage/exampleQuestions/ExampleQuestions";
import { HistoryDrawer } from "./controls/HistoryDrawer"; 
import "./searchView.scss";
import { AppHeader } from "@components/appHeader/AppHeader";
import { AppFooter } from "@components/appFooter/AppFooter";
import { useSearchContext } from "@services/context/SearchContext";
import { useConfigContext } from "@services/context/ConfigurationContext";

export const SearchView = () => {
  const { interfaceType, isConfigLoaded} = useConfigContext();

  const {
    isSearching,
    searchError,
    searchResults,
    isSummarizing,
    summarizationError,
    summarizationResponse,
    isLandingPage
  } = useSearchContext();

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  let content;

  if (!isConfigLoaded) {
    content = (
      <VuiFlexContainer
        className="appSpinner"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <VuiSpinner size="l" />
        <VuiSpacer size="l" />
        <VuiTitle size="xs">
          <h2>Loading</h2>
        </VuiTitle>
      </VuiFlexContainer>
    );
  } else if (
    (!isSearching &&
    !searchError &&
    !searchResults &&
    !isSummarizing &&
    !summarizationError &&
    !summarizationResponse && interfaceType=="searchEngine")
    || (isLandingPage && interfaceType=="searchEngine")
  ) {
    content = <ExampleQuestions />;
  } else if(
    (!isSearching &&
      !searchError &&
      !searchResults &&
      !isSummarizing &&
      !summarizationError &&
      !summarizationResponse && interfaceType=="chatbot")
      || (isLandingPage && interfaceType=="chatbot")
  ) {
    content = <SearchResults 
                isSearching={false}
              />
  }
  else {
    const summary = summarizationResponse;
    content = (
      <>
        {
          interfaceType=="searchEngine" &&
          <VuiSpacer size="s" />
        }
        
        <SearchSummary
          isSummarizing={isSummarizing}
          summarizationError={summarizationError}
          summary={summary}
        />

        {
          interfaceType=="searchEngine" &&
          <>
            <VuiSpacer size="l" />
            <VuiHorizontalRule />
            <VuiSpacer size="l" />
          </>
        }
        
        <SearchResults
          isSearching={isSearching}
          searchError={searchError}
          results={searchResults}
        />
      </>
    );
  }

  return (
    <>
      <AppHeader />
      <VuiFlexContainer
        className="appContent"
        direction="column"
        alignItems="center"
        justifyContent="spaceBetween"
        spacing="none"
      >
        {isConfigLoaded && interfaceType=="searchEngine" && (
            <SearchControls
              isHistoryOpen={isHistoryOpen}
              onToggleHistory={() => setIsHistoryOpen(!isHistoryOpen)}
              hasQuery={Boolean(isSearching || searchResults)}
            />
        )}

        <VuiFlexItem grow={1} className="searchContent">
          {content}
        </VuiFlexItem>

        {interfaceType=="searchEngine" &&
          <HistoryDrawer
          isOpen={isHistoryOpen}
          onClose={() => setIsHistoryOpen(false)}
        />
        }
        {/* FOOTER  */}
        <AppFooter/>

      </VuiFlexContainer>
    </>
  );
};
