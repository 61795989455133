import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Badge} from '@ui/components/badge/Badge';
import {useRef} from 'react';
import { TextWithContentsProps } from '@customTypes/search';
import { parseContext } from '@services/parseContext'
import { VuiImgContainer } from '../imgContainer/ImgContainer';
import { useConfigContext } from '@services/context/ConfigurationContext';
import { Body, Rows } from '../table/Table';

const TextWithContents = ({ allText, allContent, inContent, headerColumns }: TextWithContentsProps) => {
  const config = useConfigContext();
  const START_SNIPPET_TAG = config.startSnippetTag;
  const END_SNIPPET_TAG = config.endSnippetTag;
  const END_REF_TAG = config.endRefTag;
  const LINE_SEPARATOR_TAG = config.lineSeparatorTag;

  let typeContent: string = allContent ? allContent.substring(0, allContent.indexOf(":///")) : ""
  allContent = allContent ? allContent.substring(allContent.indexOf(":///") + ":///".length, allContent.length) : ""
  allContent = typeContent.substring(0, 3) === "ref"
    ? allContent.substring(0, allContent.length - END_REF_TAG.length)
    : allContent

  const contentPreTextPost = 
    typeContent.substring(0, 5) === "table"
      ? parseContext(allContent, config, false, typeContent.substring(6, typeContent.length))
      : parseContext(allContent, config)
  const preContent = contentPreTextPost.pre
  const content = contentPreTextPost.text
  const postContent = contentPreTextPost.post

  const { pre, text, post } = parseContext(allText, config, inContent)
  let interestContent = "section"
  if(
    (allContent.substring(allContent.indexOf(START_SNIPPET_TAG)).endsWith(LINE_SEPARATOR_TAG))
    &&
    (allContent.substring(allContent.indexOf(END_SNIPPET_TAG)+ END_SNIPPET_TAG.length,allContent.indexOf(END_SNIPPET_TAG)+ END_SNIPPET_TAG.length + LINE_SEPARATOR_TAG.length) == LINE_SEPARATOR_TAG )
  ){
    interestContent = "line"
  }

    const conteneurEnfantRef = useRef<HTMLDivElement>(null);

    const ajusterLargeur = () => {
      const conteneurEnfant = conteneurEnfantRef.current;
      const image = conteneurEnfant?.querySelector('img');
  
      if (conteneurEnfant && image) {
        if (image.width < conteneurEnfant.clientWidth) {
          conteneurEnfant.style.width = `${image.width}px`;
        } else {
          conteneurEnfant.style.width = '100%';
        }
      }
    };

  return (
    <>
      {
        <React.Fragment>
          {pre &&
            <React.Fragment>{pre}</React.Fragment>
          }
        </React.Fragment>
      }
      {
        <React.Fragment>
          {text &&
            <React.Fragment>{" "}<strong>{text}</strong>{" "} </React.Fragment>
          }
        </React.Fragment>
      }
      {
        <React.Fragment>
          {post &&
            <React.Fragment>{post}</React.Fragment>
          }
        </React.Fragment>
      }
      {
        typeContent === "ref" && allContent &&
        <React.Fragment>
          <Badge text={allContent.slice(1, -1).trim()} textType="ref" color="blue" size="xs" className="vuiSummaryCitation"/>
        </React.Fragment>
      }
      {
        typeContent === "file" && preContent && preContent!.trim() &&
        <VuiImgContainer
          content={preContent}
          className="conteneur_enfant"
          onLoad = {ajusterLargeur}
        />

      }
      {
        typeContent === "file" && content && content!.trim() &&
          <VuiImgContainer
            content={content}
            className="conteneur_enfant"
            onLoad = {ajusterLargeur}
          />
      }
      {
        typeContent === "file" && postContent && postContent!.trim() &&
        <VuiImgContainer
          content={postContent}
          className="conteneur_enfant"
          onLoad = {ajusterLargeur}
        />
      }
      {
        typeContent === "bouton" &&
        <React.Fragment>
          {" "}<img src={process.env.PUBLIC_URL + "img/" + (preContent!)} alt="" /> {" "}
          {" "}<img src={process.env.PUBLIC_URL + "img/" + (content!)} alt="" />{" "}
          {" "}<img src={process.env.PUBLIC_URL + "img/" + (postContent!)} alt="" />{" "}
        </React.Fragment>
      }
      {
        typeContent.substring(0, 5) === "table" &&
        <React.Fragment>
          <br />
          <TableContainer>
            <Table sx={{ minWidth: 650, borderStyle: 'solid', borderColor: 'rgb(245,245,245)' }} aria-label="simple table">
              <TableHead sx={{ background: 'rgb(162,27,35)'}}>
                <TableRow>
                  {
                    typeContent.substring(6, typeContent.length) === "content" && headerColumns[1] &&
                    headerColumns[1].map((title, idx) => (
                      <TableCell key={"content"+idx} align="left" sx={{ color: 'white'}}>{title}</TableCell>
                    ))
                  }
                  {
                    typeContent.substring(6, typeContent.length) === "pre" && preContent && headerColumns[0] &&
                    headerColumns[0].map((title, idx) => (
                      <TableCell key={"pre"+idx} align="left" sx={{ color: 'white'}}>{title}</TableCell>
                    ))
                  }
                  {
                    typeContent.substring(6, typeContent.length) === "post" && postContent && headerColumns[2] &&
                    headerColumns[2].map((title, idx) => (
                      <TableCell key={"post"+idx} align="left" sx={{ color: 'white'}}>{title}</TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  interestContent === "line" && preContent && headerColumns[0] &&
                  <Rows content={preContent!} isStrong={false} nbColumn={headerColumns[0].length} config={config}/>
                }
                {
                  interestContent === "line" && content && headerColumns[1] &&
                  <Rows content={content!} isStrong={true} nbColumn={headerColumns[1].length} config={config}/>
                }
                {
                  interestContent === "line" && postContent && headerColumns[2] &&
                  <Rows content={postContent!} isStrong={false} nbColumn={headerColumns[2].length} config={config}/>
                }

                {interestContent === "section" && typeContent.substring(6, typeContent.length) === "pre" &&
                  allContent && headerColumns[0] &&
                  <Body content={allContent} nbColumns={headerColumns[0].length} config={config} />
                }
                {interestContent === "section" && typeContent.substring(6, typeContent.length) === "content" &&
                  allContent && headerColumns[1] &&
                  <Body content={allContent} nbColumns={headerColumns[1].length} config={config} />
                }
                {interestContent === "section" && typeContent.substring(6, typeContent.length) === "post" &&
                  allContent && headerColumns[2] &&
                  <Body content={allContent} nbColumns={headerColumns[2].length} config={config} />
                }
              </TableBody>
            </Table>
          </TableContainer>
          <br />
        </React.Fragment>
      }
    </>
  )
}

export default TextWithContents;
